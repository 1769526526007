const CONFIG = {
   // URL:"https://mtcinspection.onrender.com",
    // URL:"http://localhost:8080"
     // URL:"http://ec2-13-201-75-192.ap-south-1.compute.amazonaws.com:8080"
    URL:"https://caring-creation-production.up.railway.app"
    

};

export default CONFIG;
